jQuery(document).ready(function($) {
	$('.collapsible-header').on('click', function(event) {
		const eventTarget = event.target
		const actualTarget = $(eventTarget)
		const panel = actualTarget.next(".collapsible-content")
		togglePanel(actualTarget,panel)
	});

	$('.collapsible-header p').on('click', function(event) {
		const eventTarget = event.target
		const actualTarget = $(eventTarget).parent()
		const panel = actualTarget.next(".collapsible-content")
		togglePanel(actualTarget,panel)
	});

	function togglePanel(target,panel) {
		if (target.get(0).classList.contains("open")) {
			panel.slideUp();
		}
		else {
			panel.slideDown();
		}

		target.get(0).classList.toggle("open")
	}

	$('.collapsible-header').each(function(index, element) {
		$(this).next(".collapsible-content").hide();
	});
});
